import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pagination: {
    total: "",
    currentPage: 1,
  },
};

const groupToProfileSlice = createSlice({
  name: "gtpPagination",
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const { setPagination } = groupToProfileSlice.actions;
export default groupToProfileSlice.reducer;
